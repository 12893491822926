<template>
  <div>
    <head-layout head-title="培训计划(年度)" :head-btn-options="headBtnOptions" @head-add="addFn">
    </head-layout>

    <grid-head-layout
      ref="searchForm"
      v-model="searchForm"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"/>

    <grid-layout
      ref="gridLayout"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :gridRowBtn="gridRowBtn"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="toView"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-view="toSee"
      @grid-romve="toDel"
      @grid-execute="toExecute2"
      :searchColumns="searchColumns"
    >
      <template slot="trainType" slot-scope="{ row }">
        {{ showtype(row.trainType) }}
      </template>

      <template #customBtn="{ row, index }">
        <el-button
          size="small"
          type="text"
          v-if="row.status==1"
          @click.stop="toRefer(row, index)"
        >
          提交
        </el-button>
        <el-button
          v-if="row.status==1"
          size="small"
          type="text"
          @click.stop="toSee(row, index)"
        >
          编辑
        </el-button>
        <el-button
          v-if="row.status==1"
          size="small"
          type="text"
          @click.stop="toDel(row, index)"
        >
          删除
        </el-button>
        <el-button
          v-if="row.status!==1"
          size="small"
          type="text"
          @click.stop="headProgress(row, index)"
        >
          流程进度
        </el-button>
      </template>
    </grid-layout>
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    </el-form>
    <CommonDialog v-if="dialogVisible" width="30%" dialogTitle="提示" @cancel="dialogVisible = false" @confirm="SureToExecute">
      <span>{{ dialogtext }}</span>
    </CommonDialog>

    <CommonDialog v-if="processVisible" width="70%" dialogTitle="流程进度" @cancel="processVisible = false" :showBtn="false" >
      <bpmn-flow ref="bpmnFlow" :bpmnOption="bpmnOption" :flow="flow"></bpmn-flow>
    </CommonDialog>
  </div>
</template>

<script>
import {executeSchedule} from "@/api/dataAcquisition/index";
import {
  dictionaryBiz,
  gettrainingPlanpage,
  DeltrainingPlan,
  useTrainingId,
  trainingPlanStatusUpdate, trainingPlanIdUpdate
} from "@/api/safe/index";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue';
import {detail, processList as getProcessList} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";
import CommonDialog from "@/components/CommonDialog";
import bpmnFlow from "@/views/plugin/workflow/components/process-bpmn-flow/index.vue";
export default {
  components: {
    HeadLayout,
    GridLayout,
    WfFlow,
    CommonDialog,
    bpmnFlow
  },
  props: {},
  mixins: [exForm, draft],
  data() {
    return {
      bpmnOption: {},
      circulationVisible: false,
      processVisible: false,
      bpmnVisible: false,
      trainTypeList: [],
      total: '',
      dialogtext: '',
      runState: "",
      btnType: '',
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      dialogVisible: false,
      tableLoading: false,
      ExecuteId: "",
      tableData: [],
      processId: '',
      ruleForm: {},
      formProcess: {
        id: '',
        processId: '',
        data: {},
      },
      gridRowBtn: [
      ],

      headBtnOptions: [
        {
          label: '新增',
          emit: "head-add",
          type: "button",
          icon: "",
        },
      ],

      searchColumns: [
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "计划年度",
          prop: "annualPlan",
          span: 4,
        },
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "培训组织",
          prop: "reportDept",
          span: 4,
        },
        {
          placeholder: this.$t('cip.cmn.rule.selectWarning') + "状态",
          type: "select",
          prop: "status",
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_training_plan_status",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: "number",
        },
      ],

      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 150,
        selection: false,
        indexLabel: "序号",
        index: true,
        linklabel: "annualPlan",
        column: [
          {
            label: '计划年度',
            prop: "annualPlan",
            align: 'center',
            overHidden: true,
            width: 80
          },
          // {
          //   label: '计划名称',
          //   prop: "trainMessage",
          //   align: 'center',
          //   overHidden: true,
          //   width: 250
          // },
          {
            label: '预算总金额',
            prop: "budgetAmount",
            align: 'center',
            overHidden: true,
            width: 150
          },
          {
            label: '申请人',
            prop: "applicantPerson",
            align: 'center',
            overHidden: true,
            width: 120
          },
          {
            label: '培训组织',
            prop: "reportDept",
            align: 'left',
            overHidden: true,
          },
          {
            label: '编制时间',
            prop: "preparationTime",
            align: 'center',
            overHidden: true,
            width: 200
          },
          {
            label: '状态',
            prop: "status",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_training_plan_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            align: 'center',
            dataType: "number",
            overHidden: true,
            width: 100
          },

        ],
      },
    };
  },
  computed: {
    ...mapGetters(["permission", "language", "userInfo"]),
  },

  methods: {
    headProgress(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data
        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }
        this.process = process
        this.flow = flow
        this.processVisible = true
      })
    },
    handleFlow(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data
        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }
        this.process = process
        this.flow = flow
        this.bpmnVisible = true
      })
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page)
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      gettrainingPlanpage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then(res => {
        const data = res.data.data;
        page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },

    // 获取数据字典
    getDictory() {
      dictionaryBiz("trainType").then((res) => {
        this.trainTypeList = res.data.data;
      });
    },

    showtype(type) {
      var name = ''
      this.trainTypeList.map(item => {
        if (item.dictKey == type) {
          name = item.dictValue
        }
      })
      return name
    },
    // 提交
    toRefer(item) {
      useTrainingId({id: item.id}).then((res) => {
        let length = res.data.data.length;
        if (length != 0) {
          trainingPlanStatusUpdate({id: item.id, status: 2}).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "提交成功",
              });
              this.formProcess.id = res.data.data
              this.formProcess.data = {...item}
              this.formProcess.deptId = this.userInfo.dept_id
              this.formProcess.processBusinessName = item.trainMessage
              this.handleStartProcess5(true).then(done => {
                // 更新业务数据流程任务id
                trainingPlanIdUpdate({taskId: done.data.data, id: res.data.data}).then(resUp => {
                }).then(() => {
                  this.searchChange();
                })
                done()
              })
            }
          })
        } else {
          this.$message.warning('请添加培训明细后,再提交!')
        }
      });
    },
    //查看
    toSee(item) {
      this.$router.push({
        path: "/business/safetyenment/train/planAdd",
        query: {
          type: "edit",
          id: item.id
        },
      });
    },
    //查看
    toView(item) {
      this.$router.push({
        path: "/business/safetyenment/train/planView",
        query: {
          type: "view",
          id: item.id
        },
      });
    },

    addFn() {
      this.$router.push({
        path: "/business/safetyenment/train/planAdd",
        query: {
          type: "add",
        },
      });
    },
    //执行和删除

    toExecute(item) {
      if (item.runState == 0) {
        this.dialogtext = '是否启动此任务？'
        this.btnType = 'execute'
      }
      if (item.runState == 1) {
        this.dialogtext = '是否暂停此任务？'
        this.btnType = 'stop'
      }
      this.dialogVisible = true;
      this.ExecuteId = item.id;

      this.runState = item.runState

    },

    toExecute2(item) {
      this.dialogtext = '是否执行此任务？'
      this.btnType = 'zx'
      this.dialogVisible = true;
      this.ExecuteId = item.id;
      this.runState = item.runState
    },


    SureToExecute() {
      this.dialogVisible = false;

      if (this.btnType == 'zx') {
        executeSchedule({id: this.ExecuteId, executeState: "3"}).then(() => {
          this.onLoad(this.page)
          this.$message({
            message: "执行成功！",
            type: "success",
          });
        });
      }

      if (this.btnType == 'execute') {
        executeSchedule({id: this.ExecuteId, executeState: "1"}).then(() => {
          this.onLoad(this.page)
          this.$message({
            message: "启动成功！",
            type: "success",
          });
        });
      }
      if (this.btnType == 'stop') {
        executeSchedule({id: this.ExecuteId, executeState: "2"}).then(() => {
          this.onLoad(this.page)
          this.$message({
            message: "暂停成功！",
            type: "success",
          });
        });
      }
      if (this.btnType == 'del') {
        DeltrainingPlan({id: this.ExecuteId}).then(() => {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.onLoad(this.page)
        })
      }

    },
    toDel(item) {
      this.dialogVisible = true;
      this.dialogtext = '是否删除此计划？'
      this.ExecuteId = item.id;
      this.btnType = 'del'

    }
  },
  mounted() {
    this.getDictory()
    this.onLoad(this.page)
    // 获取可申请流程列表
    getProcessList(1, 99, {}).then((res) => {
      let process = res.data.data.records.filter(
        (item) => item.key == 'iframe_training_plan_wf'
      )[0];
      this.processId = process.id;
      this.formProcess.processId = this.processId;
    });
  },
};
</script>
<style scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}
::v-deep .bjs-powered-by {
  display: none;
}
</style>
