var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "培训计划(年度)",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add": _vm.addFn },
      }),
      _c("grid-head-layout", {
        ref: "searchForm",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayout",
        attrs: {
          tableOptions: _vm.tableOptions,
          tableData: _vm.tableData,
          tableLoading: _vm.tableLoading,
          gridRowBtn: _vm.gridRowBtn,
          "data-total": _vm.page.total,
          page: _vm.page,
          searchColumns: _vm.searchColumns,
        },
        on: {
          "grid-row-detail-click": _vm.toView,
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-view": _vm.toSee,
          "grid-romve": _vm.toDel,
          "grid-execute": _vm.toExecute2,
        },
        scopedSlots: _vm._u([
          {
            key: "trainType",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.showtype(row.trainType)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "customBtn",
            fn: function ({ row, index }) {
              return [
                row.status == 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.toRefer(row, index)
                          },
                        },
                      },
                      [_vm._v("\n        提交\n      ")]
                    )
                  : _vm._e(),
                row.status == 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.toSee(row, index)
                          },
                        },
                      },
                      [_vm._v("\n        编辑\n      ")]
                    )
                  : _vm._e(),
                row.status == 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.toDel(row, index)
                          },
                        },
                      },
                      [_vm._v("\n        删除\n      ")]
                    )
                  : _vm._e(),
                row.status !== 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.headProgress(row, index)
                          },
                        },
                      },
                      [_vm._v("\n        流程进度\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-form", {
        ref: "ruleForm",
        staticClass: "demo-ruleForm",
        attrs: { model: _vm.ruleForm, "label-width": "100px" },
      }),
      _vm.dialogVisible
        ? _c(
            "CommonDialog",
            {
              attrs: { width: "30%", dialogTitle: "提示" },
              on: {
                cancel: function ($event) {
                  _vm.dialogVisible = false
                },
                confirm: _vm.SureToExecute,
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.dialogtext))])]
          )
        : _vm._e(),
      _vm.processVisible
        ? _c(
            "CommonDialog",
            {
              attrs: { width: "70%", dialogTitle: "流程进度", showBtn: false },
              on: {
                cancel: function ($event) {
                  _vm.processVisible = false
                },
              },
            },
            [
              _c("bpmn-flow", {
                ref: "bpmnFlow",
                attrs: { bpmnOption: _vm.bpmnOption, flow: _vm.flow },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }